import React from "react";
import cx from "classnames";
import cls from "./item.module.scss";

interface ItemProps {
  img: string;
  text: string;
  line?: boolean;
  mediaLine?: boolean;
}

const Item: React.FC<ItemProps> = ({ img, text, line, mediaLine }) => (
  <div
    className={cx([
      cls.wrapper,
      line ? cls.line : mediaLine ? cls.nothing : null,
    ])}>
    <div className={cls.img}>
      <img src={img} alt="" />
    </div>
    <div className={cls.text}>
      <p>{text}</p>
    </div>
  </div>
);

export default Item;
