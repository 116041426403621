import React from "react";
import cx from "classnames";
import cls from "./product.module.scss";
interface ProductProps {
  img: string;
  text?: any;
  reverse?: boolean;
}

const Product: React.FC<ProductProps> = ({ img, text, reverse }) => {
  return (
    <div
      // style={{ flexDirection: reverse ? "row-reverse" : "row" }}
      className={cx([cls.wrapper, reverse ? cls.reversing : cls.row])}>
      <div id="composition" className={cls.image}>
        <img src={img} alt="" />
      </div>
      <div className={cls.text}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Product;
