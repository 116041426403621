import React, { useState } from "react";
import { Carousel, Input, Modal } from "antd";
import PhoneInput from "antd-phone-input";
import blur from "../../assets/images/blur.png";
import pharmacy from "../../assets/images/pharmacy.png";
import person from "../../assets/images/sardor-domla.png";
import pharmacyBottom from "../../assets/images/pharmacy-bottom.png";
import cls from "./banner.module.scss";
import Button from "../button/button";
import axios from "axios";
import Swal from "sweetalert2";
import FormItem from "antd/es/form/FormItem";
interface BannerProps {}

const contentStyle: React.CSSProperties = {
  height: "114vh",
  marginTop: "15px",
};

const Banner: React.FC<BannerProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputValue, setInputValue] = useState<any>({
    name: "",
    phoneNumber: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const sendMessage = async () => {
    const { name, phoneNumber } = inputValue;
    try {
      const texts = `Ism:${name} Raqam:+${phoneNumber?.countryCode}${phoneNumber?.areaCode}${phoneNumber?.phoneNumber} 📞`;
      console.log(texts)
      await axios.get(
        `https://api.telegram.org/bot5701742118:AAGONY2JU0KOLzR8t8rYZZHOOrbzQHkYUg4/sendMessage?chat_id=-1001741769925&text=${texts}`
      );
      Swal.fire(
        "Ma'lumotlaringiz qabul qilindi!",
        "Operatorlarimiz tez orada siz bilan boglanishadi.",
        "success"
      );
      setInputValue({ ...inputValue, name: "", phoneNumber: "" });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Xatolik",
        text: "Internet aloqangizni tekshirib qayta urinib ko'ring!",
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    sendMessage();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cls.wrapper}>
      <Carousel dots={false} autoplay>
        <div>
          <div style={contentStyle}>
            <div className={cls.bannerItemWrapper}>
              <div className={cls.left}>
                <h1>HONEYMOON</h1>
                <p>Tabiat in'om etgan ajoyib mo'jiza!</p>
                <Button onClick={showModal} label="Ma'lumot olish" />
              </div>
              <div className={cls.right}>
                <img src={pharmacy} alt="" />
                <img
                  className={cls.pharmacyBottom}
                  src={pharmacyBottom}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={contentStyle}>
            <div className={cls.bannerItemWrapper}>
              <div className={cls.personLeft}>
                <h1>Sardor domla</h1>
                <p>Tavsiya qilgan mahsulot</p>
                <Button
                  label="Videoni Ko'rish"
                  videoLink="https://www.youtube.com/watch?v=W13i_tC-b9Y"
                />
              </div>
              <div className={cls.personRight}>
                <img className={cls.person} src={person} alt="" />
                <img
                  className={cls.pharmacyBottom}
                  src={pharmacyBottom}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <img src={blur} className={cls.blur} />
      <div id="aboutCompany" className={cls.aboutCompany}>
        <h1>Kompaniya haqida</h1>
        <p>
          HONEYMOON yurtimizga 2018-yilda kirib kelgan. Shu davr mobaynida juda
          ko'p insonlarda sinaldi. Immuniteti past, prostata, shamollash, jinsiy
          zaiflik, farzandsizlik bilan kasallanganlar butkul sog'aydi. Qon
          bosimi, qon tomir kasalliklari sababli aziyat chekkan insonlar
          salomatligi yaxshilandi. Zero, Payg'ambarimiz Muhammad Sollallohu
          Alayhi Vasallam ham asalni foydali ekanligi va ming dardga davo
          ekanligi haqida aytganlar.
        </p>
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <div className={cls.contactAbout}>
          <h3>Ma’lumot qoldiring</h3>
          <p>
            Itimos to’liq ma’lumot qoldiring, mutahassislarimiz sizga batafsil
            ma’lumot berishadi
          </p>
        </div>
        <div className={cls.inputs}>
          <Input
            onChange={(e: any) =>
              setInputValue({ ...inputValue, name: e.target.value })
            }
            value={inputValue.name}
            className={cls.nameInput}
            placeholder="Ismingizni kiriting"
          />
          <div style={{ width: "80%", margin: "0 auto", marginBottom: "15px" }}>
            <PhoneInput
              className={cls.phoneInput}
              placeholder="+998 (91) 123 4567"
              onChange={(e: any) =>
                setInputValue({ ...inputValue, phoneNumber: e })
              }
              value={inputValue.phoneNumber}
              enableSearch
            />
          </div>
          <Button onClick={handleOk} label="Jo'natish" />
        </div>
      </Modal>
    </div>
  );
};

export default Banner;
