import React from "react";
import image1 from "../../assets/images/product-1.png";
import image2 from "../../assets/images/product-2.png";
import cls from "./my-products.module.scss";
import Product from "./product/product";

interface MyProductsProps {}

const products = [
  {
    img: image1,
    text: (
      <>
        <span style={{ color: "red" }}>‼️</span> Organizimimizning vitamin va
        minerallarga bo'lgan talabi hech qachon to'xtamaydi. Bu talabni
        qondirish uchun doimiy ravishda dori mahsulotlarini iste'mol qilish juda
        katta xato! Ayniqsa tabiat in'om etgan tabiiy{" "}
        <span style={{ color: "#FFB400" }}>"Honeymoon"</span> asali bo'la turib
        turli xil notabiiy kimyoviy dorilarga murojaat qilish noto'g'ridir.
      </>
    ),
    reverse: false,
  },
  {
    img: image1,
    text: (
      <>
        <h6 className={cls.text}>Bir paytning o'zida:</h6>
        <ul className={cls.listOrder}>
          <li>-Energetik kofe;</li>
          <li>-Ozdiruvchi kofe va Rayxon choyi;</li>
          <li>
            -O'zingiz sevib iste'mol qilgan asal va shokoladlarimizni xarid
            qilish imkoniyatiga egasiz.
          </li>
        </ul>
      </>
    ),
    reverse: true,
  },
  {
    img: image2,
    text: (
      <>
        <span style={{ color: "#FFB400" }}>🍯 "Honeymoon"</span> tabiiy asal va
        14 xil o'simlik ekstraktlaridan tashkil topgan preparat bo'lib
        immunitetni yaxshilaydi, quvvatni oshiradi, jinsiy zaiflikni oldini
        oladi va bepushtlikka yordam beradigan tabiiy, halol mahsulotdir.
      </>
    ),
    reverse: false,
  },
];

const MyProducts: React.FC<MyProductsProps> = () => (
  <div className={cls.wrapper}>
    <div className={cls.insideWrapper}>
      <div className={cls.myProducts}>
        <h1>Mahsulotlarimiz</h1>
      </div>
      <div id="products" className={cls.products}>
        {products.map((item) => (
          <Product img={item.img} reverse={item.reverse} text={item.text} />
        ))}

        <div className={cls.line}></div>
      </div>
    </div>
  </div>
);

export default MyProducts;
