import React from "react";
import {
  Banner,
  ClientsImages,
  MyProducts,
  Navbar,
  ProductDocumentation,
  Delivery,
  Comments,
  Questions,
  Certification,
  Contact,
  Footer,
} from "./components";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <div id="wrapper">
      <Navbar />
      <Banner />
      <ClientsImages />
      <MyProducts />
      <ProductDocumentation />
      <Delivery />
      <Comments />
      <Questions />
      <Certification />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
