import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import image1 from "../../assets/images/image-1.png";
import image2 from "../../assets/images/image-2.png";
import image3 from "../../assets/images/image-3.png";
import image4 from "../../assets/images/image-4.png";
import blur from "../../assets/images/blur.png";
import cls from "./clients-images.module.scss";

import { Pagination } from "swiper";
interface ClientsImagesProps {}

const ClientsImages: React.FC<ClientsImagesProps> = () => (
  <div className={cls.wrapper}>
    <div className={cls.imagesCategoryTop}>
      <img src={image1} alt="" />
      <img src={image2} alt="" />
      <img src={image3} alt="" />
    </div>
    <div className={cls.imagesCategoryBottom}>
      <img src={image2} alt="" />
      <img src={image4} alt="" />
      <img src={image3} alt="" />
    </div>
    <div className={cls.swiper}>
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}>
        <SwiperSlide>
          <img src={image1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image4} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
    <img className={cls.blur} src={blur} alt="" />
  </div>
);

export default ClientsImages;
