import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal } from "antd";
import SwiperCore from "swiper";

import "swiper/css/pagination";
import "swiper/css";
import cls from "./certification.module.scss";

import { Pagination, Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

interface CertificationProps {}

const Certification: React.FC<CertificationProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
  });
  const showFirstModal = () => {
    setIsModalOpen({ ...isModalOpen, first: true });
  };
  const handleFirstOk = () => {
    setIsModalOpen({ ...isModalOpen, first: false });
  };
  const handleFirstCancel = () => {
    setIsModalOpen({ ...isModalOpen, first: false });
  };

  const showSecondModal = () => {
    setIsModalOpen({ ...isModalOpen, second: true });
  };
  const handleSecondOk = () => {
    setIsModalOpen({ ...isModalOpen, second: false });
  };
  const handleSecondCancel = () => {
    setIsModalOpen({ ...isModalOpen, second: false });
  };

  const showThirdModal = () => {
    setIsModalOpen({ ...isModalOpen, third: true });
  };
  const handleThirdOk = () => {
    setIsModalOpen({ ...isModalOpen, third: false });
  };
  const handleThirdCancel = () => {
    setIsModalOpen({ ...isModalOpen, third: false });
  };

  const showFourthModal = () => {
    setIsModalOpen({ ...isModalOpen, fourth: true });
  };
  const handleFourthOk = () => {
    setIsModalOpen({ ...isModalOpen, fourth: false });
  };
  const handleFourthCancel = () => {
    setIsModalOpen({ ...isModalOpen, fourth: false });
  };

  const showFifthModal = () => {
    setIsModalOpen({ ...isModalOpen, fifth: true });
  };
  const handleFifthOk = () => {
    setIsModalOpen({ ...isModalOpen, fifth: false });
  };
  const handleFifthCancel = () => {
    setIsModalOpen({ ...isModalOpen, fifth: false });
  };

  const showSixthModal = () => {
    setIsModalOpen({ ...isModalOpen, sixth: true });
  };
  const handleSixthOk = () => {
    setIsModalOpen({ ...isModalOpen, sixth: false });
  };
  const handleSixthCancel = () => {
    setIsModalOpen({ ...isModalOpen, sixth: false });
  };
  return (
    <div id="certifications" className={cls.wrapper}>
      <div className={cls.myProducts}>
        <h1>Sertifikatlar</h1>
      </div>
      <div className={cls.swiperWrap}>
        <Swiper
          spaceBetween={10}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
          }}
          slidesPerView={5}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}>
          <SwiperSlide>
            <img
              onClick={showFirstModal}
              src={require("../../assets/images/file-1.jpg")}
              alt=""
            />
            <Modal
              open={isModalOpen.first}
              style={{ display: "flex", justifyContent: "center", padding: 50 }}
              onOk={handleFirstOk}
              onCancel={handleFirstCancel}>
              <img
                style={{ height: 600, marginTop: 20 }}
                onClick={showFirstModal}
                src={require("../../assets/images/file-1.jpg")}
                alt=""
              />
            </Modal>
          </SwiperSlide>
          <SwiperSlide>
            <img
              onClick={showSecondModal}
              src={require("../../assets/images/file-2.jpg")}
              alt=""
            />
            <Modal
              open={isModalOpen.second}
              style={{ display: "flex", justifyContent: "center", padding: 50 }}
              onOk={handleSecondOk}
              onCancel={handleSecondCancel}>
              <img
                style={{ height: 600, marginTop: 20 }}
                onClick={showSecondModal}
                src={require("../../assets/images/file-2.jpg")}
                alt=""
              />
            </Modal>
          </SwiperSlide>
          <SwiperSlide>
            <img
              onClick={showThirdModal}
              src={require("../../assets/images/file-3.jpg")}
              alt=""
            />
            <Modal
              open={isModalOpen.third}
              style={{ display: "flex", justifyContent: "center", padding: 50 }}
              onOk={handleThirdOk}
              onCancel={handleThirdCancel}>
              <img
                style={{ height: 600, marginTop: 20 }}
                onClick={showThirdModal}
                src={require("../../assets/images/file-3.jpg")}
                alt=""
              />
            </Modal>
          </SwiperSlide>
          <SwiperSlide>
            <img
              onClick={showFourthModal}
              src={require("../../assets/images/file-4.jpg")}
              alt=""
            />
            <Modal
              open={isModalOpen.fourth}
              style={{ display: "flex", justifyContent: "center", padding: 50 }}
              onOk={handleFourthOk}
              onCancel={handleFourthCancel}>
              <img
                style={{ height: 600, marginTop: 20 }}
                onClick={showFourthModal}
                src={require("../../assets/images/file-4.jpg")}
                alt=""
              />
            </Modal>
          </SwiperSlide>
          <SwiperSlide>
            <img
              onClick={showFifthModal}
              src={require("../../assets/images/file-5.jpg")}
              alt=""
            />
            <Modal
              open={isModalOpen.fifth}
              style={{ display: "flex", justifyContent: "center", padding: 50 }}
              onOk={handleFifthOk}
              onCancel={handleFifthCancel}>
              <img
                style={{ height: 600, marginTop: 20 }}
                onClick={showFifthModal}
                src={require("../../assets/images/file-5.jpg")}
                alt=""
              />
            </Modal>
          </SwiperSlide>
          <SwiperSlide>
            <img
              onClick={showSixthModal}
              src={require("../../assets/images/file-6.jpg")}
              alt=""
            />
            <Modal
              open={isModalOpen.sixth}
              style={{ display: "flex", justifyContent: "center", padding: 50 }}
              onOk={handleSixthOk}
              onCancel={handleSixthCancel}>
              <img
                style={{ height: 600, marginTop: 20 }}
                onClick={showSixthModal}
                src={require("../../assets/images/file-6.jpg")}
                alt=""
              />
            </Modal>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={cls.mediaSwiper}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}>
          <SwiperSlide>
            <img src={require("../../assets/images/file-1.jpg")} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={require("../../assets/images/file-2.jpg")} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={require("../../assets/images/file-3.jpg")} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={require("../../assets/images/file-4.jpg")} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={require("../../assets/images/file-5.jpg")} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={require("../../assets/images/file-6.jpg")} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Certification;
