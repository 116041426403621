import React from "react";
import truck from "../../assets/images/truck.png";
import message from "../../assets/images/message.png";
import percent from "../../assets/images/percent.png";
import clock from "../../assets/images/clock.png";
import pharmacy from "../../assets/images/pharmacy-icon.png";
import hundredPercent from "../../assets/images/100-percent.png";
import blur from "../../assets/images/blur.png";
import cls from "./delivery.module.scss";
import Item from "./item/item";

interface DeliveryProps {}

const Delivery: React.FC<DeliveryProps> = () => {
  const items = [
    {
      img: truck,
      text: "Bepul yetkazib berish",
      line: true,
      mediaLine: true,
    },
    {
      img: message,
      text: "Mutahassis maslahati",
      line: true,
      mediaLine: true,
    },
    {
      img: percent,
      text: "Qulay narx",
      line: true,
      mediaLine: true,
    },
    {
      img: clock,
      text: "Ta'siri sezilarli",
      line: false,
      mediaLine: true,
    },
    {
      img: pharmacy,
      text: "Iste'molga qulay",
      line: false,
      mediaLine: false,
    },
    {
      img: hundredPercent,
      text: "100% tabiiy va halol",
      line: false,
      mediaLine: false,
    },
  ];

  return (
    <div id="delivery" className={cls.wrapper}>
      <div className={cls.items}>
        {items.map((item) => (
          <Item {...item} />
        ))}
        <div className={cls.arrowLeft}></div>
        <div className={cls.arrowRight}></div>
      </div>
      <img src={blur} className={cls.blur} />
    </div>
  );
};

export default Delivery;
