import React from "react";
import cx from "classnames";
import play from "../../assets/images/play.png";
import cls from "./button.module.scss";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  videoLink?: string;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  videoLink,
  className,
}) => (
  <button onClick={onClick} className={cx(cls.btn, className)}>
    <a target="_blank" href={videoLink} rel="noreferrer">
      {label} {videoLink ? <img src={play} alt="" /> : ""}
    </a>
  </button>
);

export default Button;
