import React from "react";
import logo from "../../assets/logo/honeymon-logo.svg";
import cls from "./footer.module.scss";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className={cls.wrapper}>
      <div className={cls.wrap}>
        <div className={cls.left}>
          <img src={logo} alt="" />
          <div className={cls.footerLeftText}>
            <h4>HONEYMOON</h4>
            <p>tabiat in'om etgan ajoyib mo'jiza</p>
          </div>
        </div>
        <div className={cls.right}>
          <a href="tel">+998 (55) 500-78-78</a>
          <p>Ish vaqti 9:00 dan 20:00 gacha</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100088870063897">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://t.me/honeymoon_rasmiy_uzb">
                <i className="fa-solid fa-paper-plane"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/honeymoon_rasmiy/">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
          </ul>
          <a style={{ textDecoration: "underline" }} 
          target ="_blank"
          href="https://www.yuksaksoft.uz">
           Powered by yuksaksoft.uz
          </a>
        </div>
      </div>
      <p className={cls.poweredBy}>© Barcha huquqlar himoyalangan</p>
    </div>
  );
};

export default Footer;
