import React from "react";
import file from "../../assets/honeymoon.jpg";
import book from "../../assets/images/book.png";
import cls from "./product-doc.module.scss";

interface ProductDocumentationProps {}

const ProductDocumentation: React.FC<ProductDocumentationProps> = () => (
  <div className={cls.wrapper}>
    <div className={cls.image}>
      <img src={book} alt="" />
    </div>
    <div className={cls.text}>
      <p>
        <span style={{ color: "#fff" }}>“Honeymoon”</span> mahsuloti haqida batafsil ma'lumotni olish uchun kitobchani yukla oling:
      </p>
      <button>
        <a href={file} download>
          Yuklab olish
        </a>
      </button>
    </div>
  </div>
);

export default ProductDocumentation;
