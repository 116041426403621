import React from "react";
import { Collapse } from "antd";
import blur from "../../assets/images/blur.png";
import cls from "./questions.module.scss";

interface QuestionsProps {}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const Questions: React.FC<QuestionsProps> = () => {
  const { Panel } = Collapse;

  return (
    <div className={cls.wrapper}>
      <div className={cls.myProducts}>
        <h1>Ko’p beriladigan savollar:</h1>
      </div>
      <div className={cls.collapseWrapper}>
        <div className={cls.collapseLeft}>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel header="1. Honeymoon o'zi nima?" key="1">
              <p>Honeymoon erkak va ayollar uchun asal va 14 xil giyohdan tashkil topgan 100% tabiiy mahsulot.
Yurtimizga 2018-yildan buyon sevib iste'mol qilinadi. O'zbekiston respublikasida laboratoriya tekshiruvidan o'tkazilgan va halol sertifikatiga ega mahsulot hisoblanadi.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel header="2. Mahsulot nimalarga foyda?" key="1">
              <p>-Immunitetni ko‘tarishda;<br></br>
                 -Charchoq, holsizlik va kamquvvatlilikda;<br></br>
                 -Asabni tinchlantirishda va bosh og‘rig‘ida;<br></br>
                 -Yurak urishini yaxshilashda;<br></br>
                 -Qon tomirni kengaytirishda;<br></br>
                 -Jigarni va buyrakni tozalashda, toshlarni eritishda;<br></br>
                 -Erkaklardagi prostata va shamollashni davolashda;<br></br>
                 -Ayollardagi barcha shamollashlarda;<br></br>
                 -Jinsiy quvvatni oshirishda;<br></br>
                 -Varikoz, gemoroy va boshqa turli kasalliklarda qo‘l keladi!</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel header="3. Tasdiqdan o'tgan mahsulotmi?" key="1">
              <p>Respublika sog'liqni saqlash vazirligi tomonidan tasdiqdan o'tgan va dunyo bo'ylab sotish huquqiga ega mahsulot.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel header="4. Qanday buyurtma bersa bo'ladi?" key="1">
              <p>Mutahassisimiz sizga aniq tashxis qo'ygandan keyin mahsulotni sizning nomingizga rasmiylashtiradi. 24 soatdan 48 soat ichida mahsulot qo'lingizda bo'ladi.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
           <Panel header="5. Narxi qancha?" key="1">
              <p>
              Mahsulot mutahassis maslahati bilan kerakli mijozlarga sotiladi.
              Mutahassislarimiz maslahati mutlaqo bepul.
              </p>
            </Panel>
          </Collapse>
        </div>
        <div className={cls.collapseRight}>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
             <Panel header="6. Iste'mol qilish davomiyligi qancha?" key="1">
              <p>Mahsulot iste'mol qilish davri sizning holatingizdan kelib chiqqan holatda belgilanadi.
                 Odatda mijozlarimizga 3 oydan 6 oygacha muddat belgilanadi.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel header="7. Yetkazib berish xizmati mavjudmi?" key="1">
              <p>Albatta, yetkazib berish xizmati mavjud va mutlaqo bepul.
Undan tashqari iste'mol davrida mutahassisimiz sizdagi barcha savollarga javob berib turadi.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel
              header="8. Mahsulotni iste'mol qilishda og'riqlar bo'ladimi?"
              key="1">
              <p>Muolajaning ilk kunlarida qon tomirlaringizda kengayish hosil bo'ladi va qon yo'llari ochilishi sababli tanada biroz og'riq paydo bo'lishi mumkin. Ushbu holat ijobiy bo'lib, xavotirga o'rin yo'q. Shu sababli mahsulotni iste'mol qilishda davom ettirish tavsiya qilinadi.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel
              header="9. Qanday iste'mol qilinadi?"
              key="1">
              <p>-3 kunda 1 marta;<br></br>
                    -Nonushtadan ovqatlanishdan 30 daqiqa oldin;<br></br>
                    -Bir piyola iliq suvga yoki o'zini iste'mol qilinadi.</p>
            </Panel>
          </Collapse>
          <Collapse
            expandIcon={({ isActive }) =>
              isActive ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )
            }>
            <Panel
              header="10. Qalbakilari ham bormi?"
              key="1">
              <p>Albatta, mahsulot xalq orasida ommalashgach ba'zi bir "tadbirkor"lar tomonidan qalbakilari ham ishlab chiqarildi.
                Bu holat bo'yicha tegishli joylarga murojaat qilingan va ish olib borilmoqda.
                Esda saqlang! Turkiyadagi Honeymoon kompaniyasi O’zbekistondagi YAGONA “MO’JIZA HONEYMOON” kompaniyasi bilan shartnoma qilgan.</p>
            </Panel>
          </Collapse>
        </div>
      </div>
      <img className={cls.blur} src={blur} alt="" />
    </div>
  );
};

export default Questions;
