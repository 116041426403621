import React, { useState } from "react";
import axios from "axios";
import { Modal, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import Swal from "sweetalert2";
import Button from "../button/button";
import PhoneInput from "antd-phone-input";
import logo from "../../assets/icons/honeymoon-logo.jpg";
import bar from "../../assets/icons/menu.svg";
import x from "../../assets/icons/x.svg";
import phone from "../../assets/icons/phone.svg";
import instagram from "../../assets/icons/instagram.svg";
import telegram from "../../assets/icons/send.svg";
import facebook from "../../assets/icons/facebook.svg";
import cls from "./navbar.module.scss";
interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const [inputValue, setInputValue] = useState<any>({
    name: "",
    phoneNumber: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const sendMessage = async () => {
    const { name, phoneNumber } = inputValue;

    try {
      const texts = `Ism:${name} Raqam:+${phoneNumber?.countryCode}${phoneNumber?.areaCode}${phoneNumber?.phoneNumber} 📞`;
      await axios.get(
        `https://api.telegram.org/bot5701742118:AAGONY2JU0KOLzR8t8rYZZHOOrbzQHkYUg4/sendMessage?chat_id=-1001741769925&text=${texts}`
      );
      Swal.fire(
        "Ma'lumotlaringiz qabul qilindi!",
        "Operatorlarimiz tez orada siz bilan boglanishadi.",
        "success"
      );
      setInputValue({ ...inputValue, name: "", phoneNumber: "" });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Xatolik",
        text: "Internet aloqangizni tekshirib qayta urinib ko'ring!",
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    sendMessage();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cls.wrap}>
      {navbar ? (
        <div className={cls.mediaNavbar}>
          <div className={cls.navbarTop}>
            <img className={cls.logo} src={logo} alt="" />
            <div onClick={() => setNavbar(!navbar)} className={cls.exitBtn}>
              <img src={x} alt="" />
            </div>
          </div>
          <ul>
            <li onClick={() => setNavbar(!navbar)}>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#aboutCompany"
              >
                Kompaniya haqida
              </a>
            </li>
            <li onClick={() => setNavbar(!navbar)}>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#products"
              >
                Mahsulotlar
              </a>
            </li>
            <li onClick={() => setNavbar(!navbar)}>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#comments"
              >
                Fikrlar
              </a>
            </li>
            <li onClick={() => setNavbar(!navbar)}>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#certifications"
              >
                Sertifikatlar
              </a>
            </li>
            <li>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#delivery"
              >
                Xizmatlar
              </a>
            </li>
          </ul>

          <div className={cls.mediaPhoneNumber}>
            <p>+998 (55) 500-78-78</p>
            <span>Har kuni 9:00 dan 20:00 gacha</span>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100088870063897"
                >
                  <img src={facebook} alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/honeymoon_rasmiy_uzb">
                  <img src={telegram} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/honeymoon_rasmiy/"
                >
                  <img src={instagram} alt="" />
                </a>
              </li>
            </ul>
            <a className={cls.link} target="_blank" href="https://yuksaksoft.uz">
              Powered by yuksaksoft.uz
            </a>
          </div>
        </div>
      ) : (
        <div className={cls.wrapper}>
          <div onClick={() => setNavbar(!navbar)} className={cls.hamburger}>
            <img src={bar} alt="" />
          </div>
          <ul className={cls.left}>
            <li>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#aboutCompany"
              >
                Kompaniya haqida
              </a>
            </li>
            <li>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#products"
              >
                Mahsulotlar
              </a>
            </li>
            <li>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#comments"
              >
                Fikrlar
              </a>
            </li>
          </ul>
          <img className={cls.logo} src={logo} alt="" />
          <ul className={cls.right}>
            <li>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#certifications"
              >
                Sertifikatlar
              </a>
            </li>
            <li>
              <a
                style={{ color: "#000", textDecoration: "none" }}
                href="#delivery"
              >
                Xizmatlar
              </a>
            </li>
            <Button onClick={showModal} label="Bog'lanish" />
          </ul>
          <div className={cls.mediaBtn}>
            <a onClick={showModal}>
              <img src={phone} alt="" />
            </a>
          </div>
          <Modal open={isModalOpen} onCancel={handleCancel}>
            <div className={cls.contactAbout}>
              <h3>Ma’lumot qoldiring</h3>
              <p>
                Itimos to’liq ma’lumot qoldiring, mutaxassislarimiz sizga
                batafsil ma’lumot berishadi
              </p>
            </div>
            <div className={cls.inputs}>
              <Input
                onChange={(e: any) =>
                  setInputValue({ ...inputValue, name: e.target.value })
                }
                value={inputValue.name}
                className={cls.nameInput}
                placeholder="Ismingizni kiriting"
              />
              <div
                style={{ width: "80%", margin: "0 auto", marginBottom: "15px" }}
              >
                <PhoneInput
                  className={cls.phoneInput}
                  placeholder="+998 (91) 123 4567"
                  onChange={(e: any) =>
                    setInputValue({ ...inputValue, phoneNumber: e })
                  }
                  value={inputValue.phoneNumber}
                  enableSearch
                />
              </div>
              <Button onClick={handleOk} label="Jo'natish" />
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Navbar;
