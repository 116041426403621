import React, { useState } from "react";
import { Input } from "antd";
import PhoneInput from "antd-phone-input";
import cls from "./contact.module.scss";
import Button from "../button/button";
import axios from "axios";
import Swal from "sweetalert2";

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  const [inputValue, setInputValue] = useState<any>({
    name: "",
    phoneNumber: "",
  });

  const sendMessage = async () => {
    const { name, phoneNumber } = inputValue;

    try {
      const texts = `Ism:${name} Raqam:+${phoneNumber?.countryCode}${phoneNumber?.areaCode}${phoneNumber?.phoneNumber} 📞`;
      await axios.get(
        `https://api.telegram.org/bot5701742118:AAGONY2JU0KOLzR8t8rYZZHOOrbzQHkYUg4/sendMessage?chat_id=-1001741769925&text=${texts}`
      );
      Swal.fire(
        "Ma'lumotlaringiz qabul qilindi!",
        "Operatorlarimiz tez orada siz bilan boglanishadi.",
        "success"
      );
      setInputValue({ ...inputValue, name: "", phoneNumber: "" });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Xatolik",
        text: "Internet aloqangizni tekshirib qayta urinib ko'ring!",
      });
    }
  };

  return (
    <div className={cls.wrap}>
      <div className={cls.wrapper}>
        <div className={cls.myProducts}>
          <h1>Aloqa</h1>
        </div>

        <div className={cls.contactAbout}>
          <p>
            Itimos to’liq ma’lumot qoldiring, mutahassislarimiz sizga batafsil
            ma’lumot berishadi
          </p>
        </div>
        <div className={cls.inputs}>
          <Input
            onChange={(e: any) =>
              setInputValue({ ...inputValue, name: e.target.value })
            }
            value={inputValue.name}
            className={cls.nameInput}
            placeholder="Ismingizni kiriting"
          />
          <div className={cls.wrapPhoneInput}>
            <PhoneInput
              className={cls.phoneInput}
              placeholder="+998 (91) 123 4567"
              onChange={(e: any) =>
                setInputValue({ ...inputValue, phoneNumber: e })
              }
              value={inputValue.phoneNumber}
              enableSearch
            />
          </div>
          <Button
            className={cls.shine}
            onClick={sendMessage}
            label="Maslahat olish"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
