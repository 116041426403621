import React, { useState } from "react";
import ReactPlayer from "react-player";
// import avatar_man from "../../assets/images/avatar_man.png";
// import avatar_women from "../../assets/images/avatar_women.jpg";
import Carousel from "react-bootstrap/Carousel";
import cls from "./comments.module.scss";

interface CommentsProps {}

const Comments: React.FC<CommentsProps> = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };
  return (
    <div id="comments" className={cls.wrapper}>
      <div className={cls.videoWrapper}>
        <ReactPlayer
          width="100%"
          height="100%"
          url="https://www.youtube.com/watch?v=W13i_tC-b9Y"
        />
      </div>
      <div className={cls.sliderWrapper}>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <div className={cls.avatar}>
              {/* <img src={avatar_women} alt="" /> */}
              <p>Nilufar Ochilbekova</p>
            </div>
            <div className={cls.itemText}>
              <span>23-aprel, 2023-yil</span>
              <p>
                “Honeymoon mahsulotini ichdim, shundan keyin kasallik nimaligini
                bilmadim. Uydagilarimiz tomog'i og'rib grip bo'lishdi. Lekin men
                anomal sovuqdayam kasal bo'lmadim. Yaxshi mahsulot ekan tavsiya
                qilamiz.”{" "}
              </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={cls.avatar}>
              {/* <img src={avatar_man} alt="" /> */}
              <p>Zokir aka</p>
            </div>
            <div className={cls.itemText}>
              <span>27-aprel, 2023-yil</span>
              <p>
                “Peshob chiqarishda oxirgi paytlarda qiynalgandim, Sardor
                domlani gaplarini eshitib mahsulotdan xarid qildim. Honeymoon
                juda samaralik ekan, ko'z tegmasin natijalar ancha a'lo.
                Mutaxassislarga alohida raxmat.”{" "}
              </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={cls.avatar}>
              {/* <img src={avatar_man} alt="" /> */}
              <p>Begzod aka (doimiy mijozimiz)</p>
            </div>
            <div className={cls.itemText}>
              <span>2-may, 2023-yil</span>
              <p>
                “Nafisa opaga raxmat. Juda aniq javob berdilar, aldamadilar.
                Faqat boshida moyaklarimda, bellarimda kuchli og'riq paydo
                bo'ldi. Boshida qo'rqdim, lekin keyin o'tib ketdi hammasi. Hozir
                ereksiya yaxshi.”{" "}
              </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={cls.avatar}>
              {/* <img src={avatar_man} alt="" /> */}
              <p>Islom aka (doimiy mijoz)</p>
            </div>
            <div className={cls.itemText}>
              <span>18-may, 2023-yil</span>
              <p>
                “Haqiqatdan mahsulot zo'r ekan. Menda peshob tomchilashi bor
                edi. Ishonasizmi 1 qoshiq iste'mol qildim xolos. Faqat qalbaki
                mahsulotdan ehtiyot bo'lish kerak ekan, endi bildim.”{" "}
              </p>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Comments;
